import store from "store";
import ssoPlugin from 'services/store/ssoPlugin'
store.addPlugin(ssoPlugin)


export const isLoggedIn = () => {
	return store.get("idToken");
};

export const setAuthenticationResult = (AuthenticationResult) => {
  store.set("idToken", AuthenticationResult.idToken);
  store.set("accessToken", AuthenticationResult.accessToken);
  store.set("refreshToken", AuthenticationResult.refreshToken);
  store.set("user", {
    name: AuthenticationResult.name,
    email: AuthenticationResult.email
  })
};


export const getAccessToken = () => {
  return store.get("accessToken");
};

export const getIdToken = () => {
  return store.get("idToken");
};

export const getRefreshToken = () => {
  return store.get("refreshToken");
};

export const logout = () => {
  localStorage.clear();
  store.clearAll();
};

/**
 * The profile data you get from "/profile/secured/profiledata"
 * @param {object} userProfile
 * @property {name} userProfile.name
 * @property {email} userProfile.email
 * @property {phoneNumber} userProfile.phoneNumber
 * @property {gender} userProfile.gender
 * @property {company} userProfile.company
 * @property {industry} userProfile.industry
 * @property {level} userProfile.level
 * @property {photoProfileUrl} userProfile.photoProfileUrl
 * @returns 
 */
export const setUser = (userProfile) => {
  return store.set("user", userProfile)
}

export const getUser = () => {
  return store.get("user")
}