module.exports = ssoPlugin

let cookieConfigDomain = null
if (process.env.REACT_APP_COOKIE_CONFIG_DOMAIN) {
  cookieConfigDomain = `;domain=${process.env.REACT_APP_COOKIE_CONFIG_DOMAIN}`
}
function ssoPlugin() {
  return {
    set: ssoSet,
    get: ssoGet,
    remove: ssoRemove,
    clearAll: ssoClearAll,
  }

  function ssoSet(superFn, key, val) {
    if (
      cookieConfigDomain &&
      (key === 'idToken' || key === 'accessToken' || key === 'refreshToken')
    ) {
      console.log('sso set ', key, val)
      document.cookie = `${key}=${val}${cookieConfigDomain}`
      return val
    }
    return superFn()
  }

  function ssoGet(superFn, key) {
    if (
      cookieConfigDomain &&
      (key === 'idToken' || key === 'accessToken' || key === 'refreshToken')
    ) {
      if (document.cookie) {
        try {
          const data = document.cookie
            .split('; ')
            .find((row) => row.startsWith(`${key}=`))
            .split('=')[1]
          console.log('sso get ', key, data)
          return data
        } catch (e) {
          return null
        }
      }
    }
    return superFn()
  }

  function ssoRemove(superFn, key) {
    if (
      cookieConfigDomain &&
      (key === 'idToken' || key === 'accessToken' || key === 'refreshToken')
    ) {
      document.cookie = `${key}=;max-age=-1${cookieConfigDomain}`
      console.log('sso remove ', key)
      return null
    }
    return superFn()
  }

  function ssoClearAll(superFn) {
    if (cookieConfigDomain) {
      document.cookie = `idToken=;max-age=-1${cookieConfigDomain}`
      document.cookie = `accessToken=;max-age=-1${cookieConfigDomain}`
      document.cookie = `refreshToken=;max-age=-1${cookieConfigDomain}`
    }
    return superFn()
  }
}
