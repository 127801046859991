import Button from "@material-ui/core/Button";
import styled from "styled-components";

import styledButtonCss from './StyledButton.css.js'

const StyledButton = styled(Button)`
  border-radius: .5rem;

  padding-top: .75rem;
  padding-bottom: .75rem;

  text-transform: none;

  font-size: .875rem;
  font-weight: bold;

  &.MuiButton-contained {
    ${props => props.$color && styledButtonCss.contained[props.$color]}
  }

  &.MuiButton-outlined {
    ${props => props.$color && styledButtonCss.outlined[props.$color]}
  }

  &.MuiButton-text {
    ${props => props.$color && styledButtonCss.text[props.$color]}
  }
`;

export default StyledButton;
