import Link from "@material-ui/core/Link";
import Typography from "@atoms/Typography";
import Grid from "@atoms/Grid";

import useStyles from './Footer.styles.js'

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container>
        <Grid item xs={12}>
          <Typography $bold variant="body2" color="textSecondary" align="center">
            {`Copyright © ${new Date().getFullYear()} Daya Dimensi Indonesia. All rights reserved.`}
            {/* <Link color="inherit" href="https://material-ui.com/"> */}
            {/* </Link>{""} */}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer