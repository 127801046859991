import axios from "axios";

const cognitoEndpoint = process.env.REACT_APP_COGNITO_ENDPOINT || "";

const cognitoApi = axios.create({
  baseURL: cognitoEndpoint,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export default cognitoApi;
