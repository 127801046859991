import Page from "@atoms/Page";
import DashboardNavigation from '@organisms/DashboardNavigation'
import { makeStyles } from "@material-ui/core/styles";

import apiClient from "services/axios/clientApi";
import { isLoggedIn } from "services/auth";
import { useEffect, useContext } from "react";
import AuthContext from '@contexts/auth-context';
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    flexFlow: "column",
  },
  main: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(10),
    }
  }
}));

export default function DashboardLayout({ children }) {
  const classes = useStyles();
  const history = useHistory()
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn()) {
      apiClient.get('/profile/secured/profiledata')
        .then(res => {
          authContext.setUser({
            name: res.data.name ? res.data.name : "",
            email: res.data.email ? res.data.email : "",
            phoneNumber: res.data.phoneNumber ? res.data.phoneNumber : "",
            gender: res.data.gender ? res.data.gender : "",
            company: res.data.company ? res.data.company : "",
            industry: res.data.industry ? res.data.industry : "",
            level: res.data.level ? res.data.level : "",
            photoProfileUrl: res.data.photoProfileUrl ? res.data.photoProfileUrl : ""
          })
        })
    } else {
      history.push("/auth/login")
    }
  }, []);

  return (
    <Page className={classes.root}>
      <DashboardNavigation />
      <main className={classes.main}>{children}</main>
    </Page>
  );
}
