import Container from '@atoms/Container';
import styled from 'styled-components'

const StyledPage = styled(Container)`
  display: flex;
  flex: 1;
  max-width: none;
  padding: 0;
`

export default StyledPage