import { createContext } from "react";

const AuthContext = createContext({
  idToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  user: undefined,
  setAuthContext: () => {},
  setUser: () => {}
});

export default AuthContext;