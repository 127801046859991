import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes, routesDashboard } from "./routes.js";
import DashboardLayout from "@templates/DashboardLayout";
import AuthLayout from "@templates/AuthenticationLayout";

const Router = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => <Redirect to="/dashboard/home" />}
    />

    {routes.map(({ path, Component, exact }) => (
      <Route
        path={path}
        key={path}
        exact={exact}
        render={() => {
          return (
            <Suspense fallback={null}>
              <AuthLayout>
                <Component />
              </AuthLayout>
            </Suspense>
          );
        }}
      />
    ))}

    {routesDashboard.map(({ path, Component, exact }) => (
      <Route
        path={path}
        key={path}
        exact={exact}
        render={() => {
          return (
            <Suspense fallback={null}>
              <DashboardLayout>
                <Component />
              </DashboardLayout>
            </Suspense>
          );
        }}
      />
    ))}
    <Redirect to="/auth/404" />
  </Switch>
)

export default Router