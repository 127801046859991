import { useState } from "react";
import {
  setAuthenticationResult as setAuth,
  setUser as setUserService
} from "services/auth";

/**
 * Returns the value of AuthContext and its functions
 * @returns {object}
 */
const useAuthContextValue = () => {
  const [authContext, setAuthContext] = useState({
    idToken: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    user: {
      name: "",
      email: ""
    }
  })

  const setAuthenticationResult = (AuthenticationResult) => {
    setAuthContext({
      idToken: AuthenticationResult.idToken,
      accessToken: AuthenticationResult.accessToken,
      refreshToken: AuthenticationResult.refreshToken,
      user: {
        name: AuthenticationResult.name,
        email: AuthenticationResult.email
      }
    })
    setAuth(AuthenticationResult)
  }

  const setUser = (userProfile) => {
    const newUser = {
      ...authContext.user,
      name: userProfile.name ? userProfile.name : "",
      email: userProfile.email ? userProfile.email : "",
      phoneNumber: userProfile.phoneNumber ? userProfile.phoneNumber : "",
      gender: userProfile.gender ? userProfile.gender : "",
      company: userProfile.company ? userProfile.company : "",
      industry: userProfile.industry ? userProfile.industry : "",
      level: userProfile.level ? userProfile.level : "",
      photoProfileUrl: userProfile.photoProfileUrl ? userProfile.photoProfileUrl : ""
    }

    const newAuthContext = {
      ...authContext,
      user: newUser
    }

    setAuthContext(newAuthContext)
    setUserService(newUser)
  }

  return {
    ...authContext,
    setAuthenticationResult,
    setUser
  }
};

export default useAuthContextValue;