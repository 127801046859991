import { makeStyles } from "@material-ui/core/styles";
import Router from "./router";
import AuthContext from '@contexts/auth-context';
import useSetAuthContextValue from "@hooks/useSetAuthContextValue";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    minHeight: "100vh",
    background: theme.palette["ah-grey"].main,
  },
}));

function App() {
  const classes = useStyles();
  const authCtxVal = useSetAuthContextValue();

  return (
    <div id="App" className={classes.root}>
      <AuthContext.Provider value={authCtxVal}>
        <Router />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
