import React from "react";
import Page from "@atoms/Page";
import AuthNavigation from "@organisms/AuthNavigation"
import Footer from "@organisms/Footer"

import { useTheme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexFlow: 'column'
  },
  main: {
    display: 'flex',
    flexFlow: 'column',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(10),
    }
  }
}))

export default function AuthLayout({ children }) {
  const theme = useTheme()

  const classes = useStyles()
  return (
    <Page component="main" className={classes.root}>
      <AuthNavigation />

      <main className={classes.main}>{children}</main>

      <Footer />

    </Page>
  );
}
