import { createMuiTheme } from "@material-ui/core/styles";
import { nunitoSans } from './typography.js'

const theme = createMuiTheme({
  palette: {
    'primary': {
      main: '#7A0E15' // Same as 'ods-accent[r-4]'
    },
    'secondary': {
      main: '#3E8392' // Same as 'ah-secondary'
    },
    'ods-accent': {
      'r-2': '#B01923',
      'r-3': '#92131B',
      'r-4': '#7A0E15'
    },
    'ah-grey': {
      '3': '#80868D',
      main: '#e2e8f0'
    },
    'ah-dark-grey': {
      main: '#3E4650'
    },
    'ah-light': {
      light: '#FEFEFE',
      main: '#F5F7FA'
    },
    'ah-primary': {
      main: '#2C2E45'
    },
    'ah-secondary': {
      main: '#3E8392',
      'tosca-2': '#5EB7CA',
      'tosca-4': '#3E8392'
    },
    black: {
      'b-100': '#3E4650',
      'b-400': '#000000'
    },
    grey: {
      'g-200': '#E2E8F0',
      'g-400': '#80868D'
    },
    // I'm not sure where to put this yet,
    // but this is the default hover background color for button
    // and since it's not in the design system (Figma) yet.
    // I'm just putting this here for CSS scalability purposes. 
    default: {
      hover: '#D5D5D5',
    }
  },
  typography: {
    fontFamily: 'Nunito Sans, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [nunitoSans],
      },
    },
    MuiTypography: {
      'h1': {
        fontSize: '3rem'
      },
      'h2': {
        fontSize: '2rem'
      },
      'h3': {
        fontSize: '1.5rem'
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginTop: '0 !important',
        marginRight: '.75rem',
      }
    },
    MuiLink: {
      root: {
        fontWeight: 'bold'
      }
    },
    MuiPopover: {
      paper: {
        borderRadius: '.5rem',
      }
    }
  },
  props: {
    MuiButton: {
      variant: 'contained',
      disableElevation: true
    },
    MuiAppBar: {
      position: "static",
      elevation: 0
    }
  }
});

export default theme;
