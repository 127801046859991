import PropTypes from 'prop-types'

const Logo = ({ name, src, ...otherProps }) => {
  return <img src={src} alt={name.toLowerCase()} {...otherProps} />
};

Logo.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string.isRequired // Should be a link
}

export default Logo;
