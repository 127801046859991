/**
 * Webpack "Dynamic Import" limitation, 
 * https://github.com/webpack/webpack/issues/6680
 * 
 * I was about to make it look like "@assets/logos/applications/AssessmentHub.svg"
 * However, the above issue said "Webpack doesn't enable it by default."
 * There's a solution thought in the issue, but if it's isn't the default I think I shouldn't really do it.
 * Well, if we changed our mind, we can of course. But for now this will do.
 */
const ApplicationLogoSource = {
  ASSESSMENT_HUB: 'logos/applications/AssessmentHub.svg',
  DYALOG: 'logos/applications/Dyalog.svg',
  LEARNING_HUB: 'logos/applications/LearningHub.svg',
  ODYSSEY: 'logos/applications/Odyssey.svg',
  PODQUEST: 'logos/applications/Podquest.svg'
}

export default ApplicationLogoSource