import Logo, { constants as LogoSrc } from "@atoms/Logo";
import AppBar from "@atoms/AppBar";
import Box from "@atoms/Box";
import Button from "@atoms/Button";
import Avatar from "@atoms/Avatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Typography from "@atoms/Typography";

// For mobile menu
import SwipeableDrawer from '@atoms/SwipeableDrawer';
import IconButton from '@atoms/IconButton';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from '@material-ui/icons/Close';

// For >mobile screen
import Menu from "@atoms/Menu";
import MenuItem from "@atoms/MenuItem";
import ListItemIcon from "@atoms/ListItemIcon";
import { RouterLink } from "@atoms/Link";

import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { useState, useContext } from "react";
import AuthContext from "@contexts/auth-context";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from "./DashboardNavigation.styles.js";

const logoSource = require("@assets/" + LogoSrc.ODYSSEY).default;

const DashboardNavigation = (props) => {
  const classes = useStyles();
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { user: profile } = useContext(AuthContext);

  const navigations = [
    {
      label: "Profile",
      icon: <PersonIcon />,
      url: "/dashboard/profile",
    },
    {
      label: "Change Password",
      icon: <LockIcon />,
      url: "/dashboard/change-password",
    },
    {
      label: "Logout",
      icon: <ExitToAppIcon />,
      url: "/dashboard/logout",
    },
  ];

  // > Mobile screen functions
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // End of > mobile screen functions

  // Mobile Screen functions
  const [openDrawer, setDrawer] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawer(open);
  };

  const profileEl = <>
    {/* Avatar */}
    <Avatar
      alt={profile ? profile.name : ""}
      className={classes.avatar}
      src={
        profile.photoProfileUrl ?
          profile.photoProfileUrl :
          "https://ui-avatars.com/api/?bold=true&rounded=true&size=32&background=3E8392&color=F5F7FA&name=" + profile.name
      }
    />
    {/* Email */}
    <Typography className={classes.email} component="p">
      {profile ? profile.email : ""}
    </Typography>
  </>

  const navigationEl = navigations.map((nav) => (
    <RouterLink
      key={nav.label}
      to={nav.url}
      className={classes.navigationMenuItemLink}
    >
      <MenuItem>
        {nav.icon && (
          <ListItemIcon className={classes.navigationMenuItemIcon}>
            {nav.icon}
          </ListItemIcon>
        )}
        <Typography
          $bold
          variant="subtitle2"
          component="p"
          className={classes.navigationMenuItemLabel}
        >
          {nav.label}
        </Typography>
      </MenuItem>
    </RouterLink>
  ))

  return (
    <AppBar color="transparent" className={classes.root}>
      <RouterLink to="/">
        <Logo name="Odyssey" src={logoSource} className={classes.logo} />
      </RouterLink>

      <Box className={classes.navigation}>
        { isMobileScreen ? (
          <>
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>

            {/* Drawer */}
            <SwipeableDrawer
              anchor="right"
              open={openDrawer}
              onOpen={toggleDrawer(true)}
              onClose={toggleDrawer(false)}
              classes={{
                paper: classes.navigationDrawer
              }}
            >
              <Box display="flex" p=".75rem 1rem" alignItems="center">
                {profileEl}
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {navigationEl}
            </SwipeableDrawer>
          </>
        ) : (
          <>
            <Button
              variant="text"
              color="default"
              className={classes.navigationButton}
              onClick={handleClick}
            >
              { profileEl }
              {/* Dropdown Icon */}
              <ArrowDropDownIcon className={classes.dropdownIcon} />
            </Button>

            {/* Menu */}
            <Menu
              id="navigation-menu"
              classes={{ paper: classes.navigationMenu }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {navigationEl}
            </Menu>
          </>
        )}
      </Box>
    </AppBar>
  );
};

export default DashboardNavigation;
