import Logo, { constants as LogoSrc } from '@atoms/Logo'
import AppBar from "@atoms/AppBar";

import { makeStyles } from "@material-ui/core/styles"

const logoSource = require('@assets/' + LogoSrc.ODYSSEY).default

const useStyles = makeStyles(theme => ({
  root: {
    height: '5rem',
    flexFlow: 'row',
    padding: '1rem 4rem',
    maxHeight: '5rem',

    // Should turn these into some basic class
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
    //
    [theme.breakpoints.down('xs')]: {
      height: '5.5rem',
      marginBottom: '1.5rem',
      paddingTop: '2.25rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '0',
    }
  },
  logo: {
    width: theme.spacing(19),
    height: theme.spacing(5.5),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(17.5),
      height: theme.spacing(5)
    }
  }
}))


const AuthNavigation = (props) => {
  const classes = useStyles()
  return <AppBar color="transparent" className={classes.root}>
      <Logo name="Odyssey" src={logoSource} />
    </AppBar>;
};

export default AuthNavigation;
