import theme from '@styles/theme'
import { css } from "styled-components";

/** 
 * List of colors depend on the `variant` prop.
 * Each color will have:
 * 1. Default state
 * 2. Hover state
 * 3. Pressed state (you can use `active`)
 * 4. Disabled state
 */
const styledButtonCss = {
  contained: {
    // The 'default' here means something that came from the library.
    // Not the one I set for default.
    default: css`
      background: inherit;
      &:hover {
        background: ${theme.palette['default'].hover};
      }
    `,
    [theme.palette.primary.main]: css`
      background: ${theme.palette.primary.main};
      &:hover {
        background: ${theme.palette['ods-accent']['r-3']};
      }
      &:active {
        background: ${theme.palette['ods-accent']['r-2']};
      }
      &:disabled {
        background: ${theme.palette['grey']['g-200']};
        color: ${theme.palette['grey']['g-400']};
      }
    `
  },
  outlined: {
    default: css`
      color: ${theme.palette['black']['b-100']};
      &:hover {
        background: 'rgba(134, 214, 231, 0.16)';
        border: '1px solid ${theme.palette['ah-secondary']['tosca-2']}';
      }
      &:active {
        background: rgba(134, 214, 231, 0.24);
        border: '1px solid ${theme.palette['ah-secondary']['tosca-2']}';
        color: ${theme.palette['black']['b-400']};
        font-weight bold;
      }
      &:disabled {
        border: 1px solid ${theme.palette['grey']['g-200']};
        color: ${theme.palette['grey']['g-400']};
      }
    `
  },
  text: {
    default: css`
      color: ${theme.palette['ah-secondary']['tosca-4']};
      font-weight bold;
      &:hover {
        background: rgba(134, 214, 231, 0.16);
      }
      &:active {
        background: rgba(134, 214, 231, 0.24);
      }
      &:disabled {
        color: ${theme.palette['grey']['g-400']};
      }
    `
  }
}

export default styledButtonCss