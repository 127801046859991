import NunitoSans_Regular_Ttf from "@assets/fonts/NunitoSans/NunitoSans-Regular.ttf";

const nunitoSans = {
  fontFamily: "Nunito Sans",
  src: `
    local('NunitoSans'),
    local('NunitoSans-Regular'),
    url(${NunitoSans_Regular_Ttf}) format('ttf')
  `,
};

export { nunitoSans };
