import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "5rem",
    flexFlow: "row",
    padding: "1rem 4rem",
    maxHeight: "5rem",

    // Should turn these into some basic class
    maxWidth: theme.spacing(160),
    margin: "0 auto",
    //
    [theme.breakpoints.down("xs")]: {
      height: "5.5rem",
      marginBottom: "1.5rem",
      paddingTop: "2.25rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingBottom: "0",
    },
  },
  logo: {
    width: theme.spacing(19),
    height: theme.spacing(5.5),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(17.5),
      height: theme.spacing(5),
    },
  },
  navigation: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: theme.spacing(1.75),
    color: theme.palette["ah-dark-grey"].main,
    opacity: 0.8,
  },
  navigationButton: {
    display: "flex",
    padding: theme.spacing(2, 2),
    width: theme.spacing(32.5),
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  email: {
    fontWeight: "bold",
    marginTop: 0,
    marginBottom: 0,
    flex: 1,
    textAlign: "left",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: theme.palette['ah-dark-grey'].main,
    opacity: 0.8
  },
  dropdownIcon: {
    marginLeft: "auto",
  },
  navigationDrawer: {
    paddingTop: theme.spacing(3),
    width: '100vw'
  },
  navigationMenu: {
    width: theme.spacing(32.5),
    background: theme.palette["ah-light"]["light"],
    boxShadow: "0px 1.6px 20px rgba(62, 70, 80, 0.1)",
    borderRadius: theme.spacing(1),
  },
  navigationMenuItem: {
    padding: theme.spacing(1.5, 2),
  },
  navigationMenuItemLink: {
    textDecoration: "none !important",
  },
  navigationMenuItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1.5),
  },
  navigationMenuItemLabel: {
    color: theme.palette["ah-dark-grey"].main,
  },
}));

export default useStyles