import StyledButton from "./styles";
import theme from '@styles/theme'

import { PropTypes } from "prop-types";

const Button = ({ children, variant, color, ...otherProps }) => {

  // Mutate `filled` to `contained`
  // Since the MUI uses the term `contained` for `filled` button
  let _variant
  if (variant === 'filled') {
    variant = 'contained'
  } else {
    _variant = variant
  }

  return (
    <StyledButton
      variant={_variant}
      $color={color}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: 'filled',
  color: theme.palette.primary.main
}

Button.propTypes = {
  variant: PropTypes.oneOf(['contained', 'filled', 'outlined', 'text']),
  color: PropTypes.string.isRequired
}

export default Button;
