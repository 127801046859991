import axios from "axios";
import cognitoApi from "./cognitoOAuth.js";
import store from "store";
import { logout, getIdToken } from "services/auth";

import ssoPlugin from "services/store/ssoPlugin";
import qs from "qs";

store.addPlugin(ssoPlugin);

const serviceHost = process.env.REACT_APP_BASE_SERVICE_URL || "";
const apiClient = axios.create({
  baseURL: `${serviceHost}`,
});

apiClient.interceptors.request.use((request) => {
  const idToken = getIdToken();
  if (idToken) {
    request.headers.Authorization = `${idToken}`;
  }
  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data) {
      const { status } = data;
      if (status === "error") {
      }
    }
    return Promise.resolve(response);
  },
  async (error) => {
    // Errors handling
    const { response } = error;
    // console.dir(error);

    if (response.status === 401) {
      console.log("UnauthorizedException");
      // Request new "AccessToken" to Cognito TOKEN Endpoint
      // Request the same request with `error.config`

      if (process.env.NODE_ENV !== 'production') {
        console.log("UnauthorizedException");
        console.log("Requesting new access token...");
      }

      let cognitoResponse

      try {
        cognitoResponse = await cognitoApi
          .post(
            "/token",
            qs.stringify({
              grant_type: "refresh_token",
              client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
              refresh_token: store.get("refreshToken"),
            })
          )
      } catch(err) {
        if (process.env.NODE_ENV !== 'production') {
          // Should logout
          console.log('Requesting access token failed')
          console.error(err)
          console.dir(err)
        }
        window.location = "/dashboard/logout";
      }

      let newHeader
      if (cognitoResponse) {
        const accessToken = cognitoResponse.data.access_token;
        const idToken = cognitoResponse.data.id_token;
        store.set("accessToken", accessToken);
        store.set("idToken", idToken);
        apiClient.defaults.headers.common["Authorization"] = idToken;

        newHeader = {
          ...error.config.headers,
          Authorization: cognitoResponse.data.id_token,
        };
      }
      
      if (newHeader) {
        error.config.headers = newHeader;
        try {
          return await apiClient.request(error.config);
        } catch (err) {
          console.log('Error requesting data')
        }
      }

    }

    // Sorry, idk what are these for. It was not written by me.
    // But if this is ever needed, just uncomment it.
    // if (response.status === 403 || response.status === 401) {
    // } else if (response.status !== 403 && response.status !== 401) {
    //   if (response.data.status === 403) {
    //   }
    // }
    return Promise.reject(response);
  }
);
export default apiClient;
